<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/admin/${resource.route}`)">Back</sc-button>
        </div>
        <div class="pd">
            <sc-form :form="form" @submit="submit" v-if="form && ready">

                <template #field-dimension>
                    <sc-form-field name="Frameset dimension*">
                        <div style="display: flex;">
                            <sc-form-field :form="form" field="width" name=" ">
                                <input type="number" min="1" v-model="form.fields.width" placeholder="width"/>
                            </sc-form-field>


                            <sc-form-field :form="form" field="height" name=" ">
                                <input type="number" min="1" v-model="form.fields.height" placeholder="height"/>
                            </sc-form-field>
                        </div>
                    </sc-form-field>
                </template>

                <template #field-width>
                    <div class="test"/>
                </template>
                <template #field-height>
                    <div class="test"/>
                </template>

                <template #field-frames>
                    <sc-form-field name="Frames" :form="form" field="frames">
                        <frames-canvas :formFrames.sync="form.fields.frames" :width="form.fields.width"
                                       :height="form.fields.height"/>
                    </sc-form-field>
                </template>
            </sc-form>
        </div>
    </div>
</template>

<script>
import FramesCanvas from "@/views/app/admin/framesets/components/frames-canvas";

export default {
  name: "edit",
  components: {FramesCanvas},
  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      form: null,
      ready: false
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `Edit ${this.resource.name}`,
      url: `${this.resource.api}/${this.$route.params[this.resource.route_param]}`,
      fields: {
        name: {
          name: 'Frameset Name*'
        },
        dimension: {},
        width: {
          default: 1920
        },
        height: {
          default: 1080
        },
        frames: {}
      },
      success: () => {
        this.$notify.success(`${this.resource.name} updated`)
        this.$router.push(`/app/admin/${this.resource.route}`)
      }
    })
    this.form.get()
      .then(() => {
        this.ready = true;
      })
  },
  methods: {
    submit() {
      this.form.put();
    }
  }
}
</script>

<style scoped lang="scss">

</style>